import React, { useEffect, useLayoutEffect, useState } from 'react'

import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { UpdateDataColumn } from '../../API/UpdateDataColumn.api'
import { REST_HOUSR } from '../../router/Url'
import { LecturaNespraOurRangeTotalizador } from '../../API/LecturaNespraOurRangeTotalizador.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'

import RotateLeftTwoToneIcon from '@mui/icons-material/RotateLeftTwoTone';
import { GetLecturasM2 } from '../../API/OUR_Lecturas/GetLecturasM2.api'

//contantes
let hora = ((1*60*60*24*1000)-(1*60*60*1000))+(1*60*10*1000)//(1*60*60*24*1000)-(1*60*60*1000)//1*60*60*1000
let cambioHora_1 = new Date('2024-04-06').getTime();
let CambioHora_2 = new Date('2024-09-07').getTime();

export const LineFive = ({DATA}) => {
  // console.log('LineFour')
  console.log(DATA)
  let title = DATA.MEDIDOR
  let subTitle = DATA.TIPO
  let unidad_med = DATA.UNIDAD
  const [state, setstate] = useState();
  const [IsREnder, setIsREnder] = useState(false);
  const [IsRollBack, setIsRollBack] = useState(false);
  const [BKPData, setBKPData] = useState([]);
  const [MinMaxDataRange, setMinMaxDataRange] = useState([]);
  const [DateMin, setDateMin] = useState('');
  const [DateMmax, setDateMmax] = useState('');
  const [DateMin_F, setDateMin_F] = useState('');
  const [DateMmax_F, setDateMmax_F] = useState('');
  const [IsVisbleFilter, setIsVisbleFilter] = useState(false);
  const [IsVisbleCalculo, setIsVisbleCalculo] = useState(false);
  const [CalculoConsumoFiltrado, setCalculoConsumoFiltrado] = useState('');

  useLayoutEffect( ( ) => {
    setstate(options)
    setIsREnder(true)
  },[])

  let UpdateChartNow = async () => {
    setCalculoConsumoFiltrado('')
    // console.log(state.series[0].data)
    let OneWeek = 604800;
    let OneDay = 86400;
    let OneHour = 3600;
    let OneMinit = 60

    let now = Math.floor((new Date).getTime()/1000)//segundos now
    let pasado = now-(OneDay*7);
    let datas = []
    let arr_consumo = []

    if(DATA.CONTRATO === "MARCUXO"){
      // console.log("MARCUXO")
      datas = await GetLecturasM2({
        medidor: DATA.MEDIDOR,
        sensor: DATA.MEDIDOR==="AMP_EMPALME_1"?DATA.SENSOR:"m2",
        planta: DATA.EMPRESA,
        ahora: DateMmax_F===""?now*1000:((new Date(DateMmax_F+":59").getTime())),
        pasado: DateMin_F===""?pasado*1000:((new Date(DateMin_F+":00").getTime()))
      })
      arr_consumo = await datas.map(sss => {
        let {timestamp,valor} = sss
        return [timestamp,valor]
      })
    }else{
      datas = await LecturaNespraOurRangeTotalizador({
        medidor: DATA.MEDIDOR,
        sensor: 'm2',
        planta: DATA.EMPRESA,
        ahora: DateMmax_F===""?now:((new Date(DateMmax_F+":59").getTime())/1000),
        pasado: DateMin_F===""?pasado:((new Date(DateMin_F+":00").getTime())/1000)
      })
      arr_consumo = await datas.map(sss => {
        let {timestamp,valor} = sss
        return [(timestamp-(OneHour*REST_HOUSR))*1000,valor]
      })
    }
    // console.log(datas)
    let g = []
    // LOGICA PARA GENERAR DATOS EN CASO DE SER MEDIDOR DE NESPRA
    
    //backup de data
    setBKPData(arr_consumo)
    // define el rango minimo y maximo de seleccion de fecha 
    let MIN = new Date(arr_consumo[0][0]).toISOString().split('T')[0]
    let MAX = new Date(arr_consumo[arr_consumo.length-1][0]).toISOString().split('T')[0]
    setMinMaxDataRange([MIN, MAX])
    // console.log(new Date(arr_consumo[0][0]).toISOString());
    // console.log(new Date(arr_consumo[arr_consumo.length-1][0]).toISOString());
    g = await [{
      name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
      data: arr_consumo
    }]

    await setstate({...state,["series"]:g})
    await setIsVisbleFilter(true)
  }
  
  const FilterByDataRange = async () => {
    setIsRollBack(true)
    let g = []
    if(DateMin === '' || DateMmax === ''){
      alert('Primero debe seleccionar un rango de fechas.')
    }else{
      // console.log('FECHA MINIMA',DateMin, new Date(DateMin).getTime(),cambioHora_1);
      // console.log('FECHA MAXIMA',DateMmax, new Date(DateMmax).getTime(),CambioHora_2);
      let a_ = 0
      let b_ = 0
      if(Number(new Date(DateMin).getTime())>Number(cambioHora_1)){
        a_ = new Date(DateMin+"T00:00:00").setHours(-4);
        b_ = new Date(DateMmax+"T23:59:59").setHours(-4);
        console.log("1")
      }else{
        a_ = new Date(DateMin+"T00:00:00").setHours(-3);
        b_ = new Date(DateMmax+"T23:59:59").setHours(-3);
        console.log("2")
      }

      
      // if(Number(new Date(DateMmax).getTime())>Number(CambioHora_2)){
        
      //   console.log("3")
      // }else{
        
      //   console.log("4")
      // }
      let a = new Date(a_).getTime()
      let b = new Date(b_).getTime()
      
      console.log(new Date(a_),new Date(b_))
      if(b < a)alert('La fecha de Inicio no puede ser mayor a la Fecha final.')
      else{
        let resultFill = await BKPData.filter(flt=>Number(flt[0])>=Number(a) && Number(flt[0])<=Number(b)+hora);
        // console.log(resultFill[resultFill.length-1][1],resultFill[0][1],'$',FormatNumberCL((resultFill[resultFill.length-1][1]-resultFill[0][1])),'KW/H');
        setCalculoConsumoFiltrado(FormatNumberCL((resultFill[resultFill.length-1][1]-resultFill[0][1])),'KW/H');
        setIsVisbleCalculo(true)
        g = await [{
          name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
          data: resultFill
        }]
        await setstate({...state,["series"]:g})
      }
    }

  }

  const RollBack_ = async () => {
    setCalculoConsumoFiltrado('')
    let g = await [{
      name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
      data: BKPData
    }]
    await setstate({...state,["series"]:g})
    setIsRollBack(false)
  }

  useEffect(() => {
    // console.log('carga los datos del grafico');
    setIsVisbleFilter(false)
    UpdateChartNow()
    setInterval(() => {
      UpdateChartNow()
    }, 1000*60*30);
  
    return () => {
     UpdateChartNow() 
    }
  }, [])

  useEffect(() => {
    if(RollBack_.length>1){
      IsRollBack(true)
    }
  }, [BKPData])

  const options = {
    chart: {
      type: 'line',
      zoomType: 'x',
      height: '400px',
      navigation: {
        buttonOptions: {
          enabled: true
        }
      },
      resetZoomButton: {
        position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -30,
            text: "zooom"
        }
      }
    },
    title: {
        text: title,
        style: {
          // fontSize:'small',
          fontWeight: 'bold',
          fontFamily: 'monospace',
          color: 'black'
        }
    },
    subtitle: {
        text: 'Consumo',
        style: {
          // fontSize:'small',
          fontWeight: 'lighter',
          fontFamily: 'monospace',
          color: 'gray'
        }
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
            month: '%e. %b',
            year: '%b'
        },
        title: {
            text: ''
        }
    },
    legend: {
      align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },
    yAxis: {
        title: {
          text: `Consumos ${title} por ${unidad_med}/H`
        },
        // min: 0
    },
    tooltip: {
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(d_)
          return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '/H</b>';
        },
        // headerFormat: '<b>{series.name}</b><br>',
        // pointFormat: '{point.x}: {point.y:.2f} m³',
        // crosshairs: true,
        // shared: true
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 2
        }
      },
    },

    series: [{
      name: 'Flujometro',
      data:
      [
        [Date.UTC(2023, 0,  3), 0.39],
        [Date.UTC(2023, 0,  6), 0.39],
        [Date.UTC(2023, 0,  9), 0.39],
        [Date.UTC(2023, 0,  9), 0.99],
        [Date.UTC(2023, 0, 11), 0.19],
        [Date.UTC(2023, 0, 13), 0.99],
        [Date.UTC(2023, 0, 13), 0.39],
        [Date.UTC(2023, 0, 16), 0.39],
        [Date.UTC(2023, 0, 19), 0.39]
      ]
    }]
  }

  if(IsREnder){
    return (
      <>
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
        <div className='datemodalcum-chart_TOP'>
            <div className='div-dmf'>
              <input
                type='datetime-local'
                title='FECHA INICIO'
                // value={DateMin}
                className='input-fecha-filter'
                onChange={(e)=>setDateMin_F(e.target.value)}
                // min={MinMaxDataRange[0]}
                // max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Inicio</span>
            </div>
            <div className='div-dmf'>
              <input
                type='datetime-local'
                title='FECHA INICIO'
                // value={DateMmax}
                className='input-fecha-filter'
                onChange={(e)=>setDateMmax_F(e.target.value)}
                // min={MinMaxDataRange[0]}
                // max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Fin</span>
            </div>
            <div className='div-dmf'>
              <button
                disabled={DateMin_F===""||DateMmax_F===""?true:false}
                className='btn btn-info mt-1 pt-1'
                onClick={()=>UpdateChartNow()}//console.log(DateMin, DateMmax)}
              >buscar</button>
            </div>
        </div>
        {
          IsVisbleFilter?<div className='datemodalcum-chart_UNDER_TOP'>
          {
            IsRollBack?<div className='div-dmf'>
              <RotateLeftTwoToneIcon onClick={()=>RollBack_()} fontSize='large' color='secondary' className='restablecer' />
            </div>:null
          }
          
            <div className='div-dmf'>
              <input
                type='date'
                title='FECHA INICIO'
                // value={DateMin}
                className='input-fecha-filter'
                onChange={(e)=>setDateMin(e.target.value)}
                min={MinMaxDataRange[0]}
                max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Inicio</span>
            </div>
            <div className='div-dmf'>
              <input
                type='date'
                title='FECHA INICIO'
                // value={DateMmax}
                className='input-fecha-filter'
                onChange={(e)=>setDateMmax(e.target.value)}
                min={MinMaxDataRange[0]}
                max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Fin</span>
            </div>
            <div className='div-dmf'>
              <button className='btn btn-info mt-1 pt-1' onClick={()=>FilterByDataRange()}>buscar</button>
            </div>
            {
              CalculoConsumoFiltrado===''?null:
              <div className='' style={{position:'absolute', bottom: '-10px', paddingLeft: '25%'}}><b>El consumo es {CalculoConsumoFiltrado}KW/H</b></div>
            }
          </div>:null
        }
        
      </>
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}
