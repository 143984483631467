import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import GaugeOne from '../../components/charts/GaugeOne.chart'
import LoginContext from '../../context/login_context/LoginContext'
import { ListMedidores } from '../../API/ListMedidores.api'
import { LineFour } from '../../components/charts/LineFour.chart'

import * as htmlToImage from 'html-to-image';
import GaugeOneElectric from '../../components/charts/GaugeOneElectric.chart'
import { LineFive } from '../../components/charts/LineFive.chart'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api'
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

//CONSTANTES
let corriente = 192.6
let potencia = 103.7
let factor = 1.7
// console.log('factor',(646.87*factor).toFixed(0));
export const ChartsElectric = () => {

    const [ListMedidores_, setListMedidores_] = useState([]);
    const [ListMedidores_NF, setListMedidores_NF] = useState([]);
    const [ShowmetheModal_, setShowmetheModal_] = useState(false);
    const [ShowmetheModal_ACUM, setShowmetheModal_ACUM] = useState(false);
    const [MedidorToModal, setMedidorToModal] = useState('');

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState.planta)

    const getIMG = async (id) => {
        var node = document.getElementById(id);
        
    
        htmlToImage.toSvg(node)
        .then(function (dataUrl) {
          
            // console.log(dataUrl);
            let anchor = document.createElement("a")
            anchor.href = dataUrl
            anchor.download = "MyQRIsHere.png"
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        
            // bringMeTheCode()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
      }

    const GetListMedidores = async () => {
        let list_OUR = await GetListMEdidores({ empresa: LoginState.planta });
        let listFil_OUR = await list_OUR.filter(fl => fl.TIPO === "ELECTRICO");
        let listFil_OUR_m1 = await listFil_OUR[0]["MEDIDOR"].filter(fl => fl.SENSOR === "m1");
        let listFil_OUR_m1_KW = await listFil_OUR_m1.filter(fl => String(fl.MEDIDOR).startsWith("KW") || String(fl.MEDIDOR).startsWith("V"));
        setListMedidores_NF(listFil_OUR_m1);
        // console.log(listFil_OUR_m1,listFil_OUR_m1_KW)

        let list_ = await ListMedidores({empresa:LoginState.planta})
        let listFil = await list_["m1"].filter(fl => fl.ELEMENTO === "ELECTRICO")
        let a = []
        a = listFil_OUR_m1_KW.concat(listFil)
        setListMedidores_(a);
        return (<div>menu flotante</div>)
    }

    const ShowModalData = async (data) => {
        // console.log(data);
        setMedidorToModal(data);
        setShowmetheModal_(true)
    }

    const ShowModalData_ACUM = async (data) => {
        // console.log(data);
        setMedidorToModal(data);
        setShowmetheModal_ACUM(true)
    }

    const ShowModalData_I = async (data) => {
        let medidorI = await ListMedidores_NF.filter(fl => fl.MEDIDOR === data)
        console.log("Corriente",medidorI[0]);
        setMedidorToModal(medidorI[0]);
        setShowmetheModal_ACUM(true)
    }

    useEffect(() => {
        GetListMedidores()
        return () => {
            GetListMedidores()
        }
    }, [])

    return (
        <>
            <HeaderMenu Pag_Sel={'electric'} />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                        {/* <button className='btn w-25 btn-info btn-sm' onClick={()=>CloseMenuSettings(!SettingsMenu)}>Cerrar</button> */}
                        </div>
                        <div className='col-12 py-2 mt-2 card-chart-line'>
                            <div className='row'>

                                {
                                    ListMedidores_.map(cl => (
                                    <div key={cl._id} id={cl._id} className='calcule-chart-space'
                                        style={{cursor: 'pointer'}}
                                    >
                                        <GaugeOneElectric
                                            key={cl._id+"01"}
                                            DATA={cl}
                                            ShowModalData={ShowModalData}
                                            ShowModalData_ACUM={ShowModalData_ACUM}
                                            ShowModalData_I={ShowModalData_I}
                                            />
                                    </div>
                                    ))
                                }

                            </div>
                        </div>

                        
                    </div>
                </div>

                {
                    ShowmetheModal_?
                        <div className='modal_chdata'>
                            <button
                                onClick={()=>setShowmetheModal_(false)}
                                className='btn btn-danger btn-sm'
                                style={{position: 'absolute', top: '10px', left: '10px'}}
                                >Cerrar</button>
                            <div className='bg-info w-100 text-center'>
                                <LineFour DATA={MedidorToModal} />
                                {/* <LineFive DATA={MedidorToModal} /> */}
                            </div>
                        </div>
                    :null
                }

                {
                    ShowmetheModal_ACUM?
                        <div className='modal_chdata'>
                            <button
                                onClick={()=>setShowmetheModal_ACUM(false)}
                                className='btn btn-danger btn-sm'
                                style={{position: 'absolute', top: '10px', left: '10px'}}
                                >Cerrar</button>
                            <div className='bg-info w-100 text-center'>
                                {/* <LineFour DATA={MedidorToModal} /> */}
                                <LineFive DATA={MedidorToModal} />
                            </div>
                        </div>
                    :null
                }
        </>
    )
}
