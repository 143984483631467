
import * as htmlToImage from 'html-to-image';
import { LineFive } from '../../components/charts/LineFive.chart'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api'
import OUR_GaugeOneTest from '../../components/charts/OUR_GaugeOneTest.chart'
import { LineOneDay } from '../../components/charts/MonitorFijo/LineOneDay.chart';
import { LineOneDayTemp } from '../../components/charts/LineOneDayTemp.chart';
import { HeaderMenu } from '../../components/HeaderMenu.comp';
import { GetDatosCaldera } from '../../API/DatosCaldera/GetDatosCaldera.api';
import { OURLineFourCaldera } from '../../components/charts/OURLineFourCaldera.chart';
import { OURLineFourIOCH } from '../../components/charts/OURLineFourIOCH.chart';
import { useContext, useEffect, useState } from 'react';
import LoginContext from '../../context/login_context/LoginContext';

export const VaporChart = () => {
    const [ListMedidores_, setListMedidores_] = useState([]);
    const [ShowmetheModal_, setShowmetheModal_] = useState(false);
    const [ShowmetheModal_ACUM, setShowmetheModal_ACUM] = useState(false);
    const [MedidorToModal, setMedidorToModal] = useState('');

    //mutadores y getters de fechas
    const [DateMin, setDateMin] = useState('');
    const [DateMmax, setDateMmax] = useState('');
    const [Updater, setUpdater] = useState(1);

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState.planta)

    const getIMG = async (id) => {
        var node = document.getElementById(id);
        
    
        htmlToImage.toSvg(node)
        .then(function (dataUrl) {
          
            // console.log(dataUrl);
            let anchor = document.createElement("a")
            anchor.href = dataUrl
            anchor.download = "MyQRIsHere.png"
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        
            // bringMeTheCode()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }

    const GetListMedidores = async () => {
        let ONE_WEEK = 1000 * 60 * 60 * 24 * 7
        // let list_ = await GetDatosCaldera({inicio:((new Date().getTime()) - ONE_WEEK), fin:null})
        let list_OUR = await GetListMEdidores({ empresa: "INDUSTRIAL OCHAGAVIA LTDA." });
        let listFil_OUR = await list_OUR.filter(fl => fl.TIPO === "VAPOR");
        let listFil_OUR_ = await list_OUR.filter(fl => fl.TIPO === "HIDRICO");
        (listFil_OUR_[0].MEDIDOR).concat(listFil_OUR[0].MEDIDOR)
        console.log((listFil_OUR_[0].MEDIDOR).concat(listFil_OUR[0].MEDIDOR));
        setListMedidores_((listFil_OUR_[0].MEDIDOR).concat(listFil_OUR[0].MEDIDOR));
    }

    const ShowModalData = async (data) => {
        // console.log(data);
        data["SENSOR"] = "m1"
        setMedidorToModal(data);
        setShowmetheModal_(true)
    }

    useEffect(() => {
        GetListMedidores()        
        return () => {
            GetListMedidores()
        }
    }, [])

    return (
        <>
        <HeaderMenu Pag_Sel={'/v2/editormedidores'} />
            <div className='container-fluid pt-5'>
                <div className='row'>
                <div className='col-12 col-md-3'></div>
                <div className='col-12 col-md-9'>
                    <div className='datemodalcum-chart_fourOURIOCH'>
                        <div className='div-dmf'>
                            <input
                            type='datetime-local'
                            title='FECHA INICIO'
                            value={DateMin}
                            className='input-fecha-filter'
                            onChange={(e)=>setDateMin(e.target.value)}
                            // min={MinMaxDataRange[0]}
                            // max={MinMaxDataRange[1]}
                            />
                            <span className='txt-input-fecha-filter-fourOURIOCH'>Fecha Inicio</span>
                        </div>
                        <div className='div-dmf'>
                            <input
                            type='datetime-local'
                            title='FECHA INICIO'
                            value={DateMmax}
                            className='input-fecha-filter'
                            onChange={(e)=>setDateMmax(e.target.value)}
                            // min={MinMaxDataRange[0]}
                            // max={MinMaxDataRange[1]}
                            />
                            <span className='txt-input-fecha-filter-fourOURIOCH'>Fecha Fin</span>
                        </div>
                        <div className='div-dmf'>
                            <button
                                disabled={DateMin===""||DateMmax===""?true:false}
                                className='btn btn-info mt-1 pt-1'
                                onClick={()=>setUpdater(Updater+1)}//console.log(DateMin, DateMmax)}
                            >buscar</button>
                        </div>
                    </div>
                </div>
                    {ListMedidores_.map((ulx, index) => (
                        <div key={index} className="col-12 text-center">
                            <div className="col-12" key={index}>
                                {
                                    <OURLineFourIOCH
                                        ShowModalData={ShowModalData}
                                        DATA={ulx}
                                        DateMin={DateMin}
                                        DateMmax={DateMmax}
                                        Updater={Updater}
                                    />
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>

                {
                    ShowmetheModal_?
                        <div className='modal_chdata'>
                            <button
                                onClick={()=>setShowmetheModal_(false)}
                                className='btn btn-danger btn-sm'
                                style={{position: 'absolute', top: '10px',left: '50%'}}
                                >Cerrar</button>
                            <div className='w-100 text-center'>
                                <OURLineFour DATA={MedidorToModal} />
                                {/* <LineFive DATA={MedidorToModal} /> */}
                            </div>
                        </div>
                    :null
                }
        </>
    )
}
